/* eslint-disable jsx-a11y/control-has-associated-label */
import { useAppContext } from '@/contexts/appContext';
import { Tooltip } from '@mantine/core';
import cx from 'classnames';
import { Copy, Envelope } from 'phosphor-react';
import { useMemo, useState } from 'react';

interface NotifyButtonProps {
    className?: string;
    accountID: string;
    section: string;
}

export function NotifyButton({ section, accountID, className }: NotifyButtonProps) {
    const { user } = useAppContext();
    const [isCopied, setIsCopied] = useState(false);
    const email = 'abi-ghq-support@arena-ai.com';
    const adjustedSubject = useMemo(
        () => `Question about ${section} personalizations ${accountID || ''}`,
        [accountID, section],
    );
    const encodedSubject = encodeURIComponent(adjustedSubject);
    const greeting = 'Hello Arena Staff,\n\n';
    const questionContent =
        `This account has no personalization in ${section}: Yes / No\n` +
        `This account doesn’t see the default recommendation in ${section}: Yes / No\n` +
        `This account has incorrect personalization in ${section}: [ Type the observed problem here ]\n\n`;
    const userName = user?.firstName && user?.lastName ? `${user.firstName} ${user.lastName}` : '';
    const closing = useMemo(() => `Best regards,\n\n${userName}`, [userName]);
    const body = encodeURIComponent(greeting + questionContent + closing);
    const mailtoLink = `mailto:${email}?subject=${encodedSubject}&body=${body}`;

    const handleCopyClick = async () => {
        const textToCopy = `${adjustedSubject}\n\n${greeting}${questionContent}${closing}`;
        try {
            await navigator.clipboard.writeText(textToCopy);
            setIsCopied(true);
            setTimeout(() => setIsCopied(false), 2000);
        } catch (err) {
            console.error('Failed to copy text: ', err);
        }
    };

    const tooltipContent = useMemo(
        () => (
            <div style={{ whiteSpace: 'pre-line', maxWidth: '300px' }}>
                <span className="font-light">Copy message:</span>
                <br />
                <br />
                <span className="font-semibold">{adjustedSubject}</span>
                <br />
                <br />
                <span className="font-normal">
                    {greeting.trim()}
                    <br />
                    <br />
                    {questionContent.trim()}
                    <br />
                    <br />
                    {closing}
                </span>
            </div>
        ),
        [adjustedSubject, greeting, questionContent, closing],
    );

    return (
        <div className="flex items-center space-x-1">
            <a
                href={mailtoLink}
                className={cx(
                    'text-[#245ef7] hover:opacity-70 text-sm flex space-x-1 items-center',
                    className,
                )}
            >
                <button type="button">Notify Arena </button>
                <Envelope size={14} />
            </a>
            <div className="hover:opacity-70">
                <Tooltip label={isCopied ? 'Copied!' : tooltipContent}>
                    <button type="button" onClick={handleCopyClick}>
                        <Copy size={14} color="#245ef7" />
                    </button>
                </Tooltip>
            </div>
        </div>
    );
}
