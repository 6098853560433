import { Check, Warning } from 'phosphor-react';
import { useMutation } from '@tanstack/react-query';
import { SPACEPORT_API_URL } from '../../buildConfig/processEnv';
import { useAppContext } from '../../contexts/appContext';
import colors from '../../constants/colors';
import { notification } from '../../utils/notification';

interface FeedbackResponse {
    response: string;
}

interface MutateFnParams {
    products: string[];
    type: string;
    level: string;
    feedback: string;
}

export const useSendFeedback = (namespace: string, setOpenModal: (bool: boolean) => void) => {
    const { user } = useAppContext();

    return useMutation<FeedbackResponse, Error, MutateFnParams>({
        mutationFn: async ({ products, type, level, feedback }) => {
            const url = `${SPACEPORT_API_URL}/spaceport/api/email/save_model_feedback`;
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${user.siberiaToken}`,
                },
                body: JSON.stringify({
                    namespace,
                    products,
                    store_type: type,
                    recommendation_level: level,
                    additional_feedback: feedback,
                }),
            });
            if (!response.ok) {
                throw new Error('Failed to send feedback.');
            }
            return response.json();
        },
        mutationKey: ['useSendFeedback', namespace],
        onSuccess: () => {
            notification({
                color: colors.darkGreen,
                icon: <Check />,
                message: 'Feedback recorded.',
            });
            setOpenModal(false);
        },
        onError: () => {
            notification({
                color: colors.red,
                icon: <Warning />,
                message: 'Failed to send feedback.',
            });
        },
    });
};
