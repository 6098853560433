import { SPACEPORT_API_URL } from '../../buildConfig/processEnv';
import useApiQuery from '../../hooks/useApiQuery';
import { UseCaseConfig } from '../../types/Personalization';
import { useConfigurationsQueryParams } from './useConfigurationsQueryParams';

export const getUseCaseConfigUrl = (useCase: string, namespace: string) =>
    `${SPACEPORT_API_URL}/spaceport/api/configurator/use_case/${useCase}/namespace/${namespace}`;

export const useActiveUseCaseConfig = (selectedNamespace?: string) => {
    const { selectedNamespace: namespace, selectedUseCase } = useConfigurationsQueryParams();
    return useApiQuery<UseCaseConfig>({
        url: getUseCaseConfigUrl(selectedUseCase, selectedNamespace || namespace),
        disabled: !(selectedNamespace || namespace),
    });
};
