import React from 'react';
import { Wrench } from 'phosphor-react';
import { useRouter } from 'next/router';
import { Card } from 'andromeda/Card';
import { primaryColors } from '@/constants/colors';
import { useRecommendationsData } from './useRecommendationsData';
import { LanguageDropdown } from '../configurationsV2/LanguageDropdown';
import { IconWrapperButton } from '../utils/IconWrapperButton';
import { usePersonalizationsQueryParams } from './usePersonalizationsQueryParams';

interface ContextualTextProps {
    selectedLanguage: string;
    setSelectedLanguage: (language: string) => void;
}

export const ContextualText: React.FC<ContextualTextProps> = ({
    selectedLanguage,
    setSelectedLanguage,
}) => {
    const router = useRouter();

    const { data, isLoading, error } = useRecommendationsData();
    const { selectedNamespace, selectedUseCase } = usePersonalizationsQueryParams();

    const contexts = data?.use_case_config?.personalization_contexts;

    const parsedJsonContexts = contexts
        ?.filter(item => item.personalization_type === 'json')
        ?.map(item => JSON.parse(item.context ?? '{}'));

    const selectedContext = parsedJsonContexts?.find(item =>
        item.language?.includes(selectedLanguage),
    );

    const contextTitle = selectedContext?.title;
    const contextDescription = selectedContext?.description;

    return (
        <Card
            title="Personalized contextual text"
            actionsElement={
                <div className="flex items-center gap-3">
                    <LanguageDropdown
                        contexts={contexts}
                        language={selectedLanguage}
                        onChange={setSelectedLanguage}
                    />
                    <IconWrapperButton
                        description="Configurations"
                        onClick={() =>
                            router.push({
                                pathname: '/configurations',
                                query: {
                                    namespace: selectedNamespace,
                                    usecase: selectedUseCase,
                                },
                            })
                        }
                    >
                        <Wrench size={22} />
                    </IconWrapperButton>
                </div>
            }
            loading={isLoading}
            error={error?.message}
        >
            {contextTitle && <h5>{contextTitle}</h5>}
            {contextDescription && <p className="mt-3">{contextDescription}</p>}
        </Card>
    );
};
