import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { useViewportSize } from '@mantine/hooks';
import cx from 'classnames';
import StyledTooltip from './styledTooltip';

const isEllipsisActive = <T extends HTMLElement>(e: T) => e.offsetWidth < e.scrollWidth;

interface ResponsiveEllipsisTextProps {
    tooltipContent?: string;
    className?: string;
}

export const ResponsiveEllipsisText: React.FC<PropsWithChildren<ResponsiveEllipsisTextProps>> = ({
    tooltipContent,
    children,
    className,
}) => {
    const { width } = useViewportSize();
    const ref = useRef<HTMLDivElement | null>(null);
    const [isEllipsis, setIsEllipsis] = useState(false);

    useEffect(() => {
        if (ref.current === null) return;
        const ellipsisActive = isEllipsisActive(ref.current);
        setIsEllipsis(ellipsisActive);
    }, [width, ref]);

    return (
        <StyledTooltip label={tooltipContent ?? children ?? ''} disabled={!isEllipsis}>
            <div ref={ref} className={cx('overflow-hidden text-ellipsis', className)}>
                {children}
            </div>
        </StyledTooltip>
    );
};
