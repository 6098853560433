/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { Card } from 'andromeda/Card';
import classNames from 'classnames';
import { Info, Link } from 'phosphor-react';
import Text from 'andromeda/text';
import { primaryColors } from '@/constants/colors';
import { Table } from '../../andromeda/Table';
import { UploadProductDataType } from '../../hooks/useUploadData';
import StyledTooltip from '../utils/styledTooltip';
import { NotifyButton } from './NotifyButton';
import StyledCopyButton from '../utils/styledCopyButton';

const columnHelper = createColumnHelper<UploadProductDataType>();

interface RecommendationsViewerSectionProps {
    title: string;
    description: string;
    tooltipLabel: string;
    dataType: 'business' | 'data';
    uploadView?: boolean;
    className?: string;
    uploadData: UploadProductDataType[];
    loading: boolean;
    error: Error;
    isThereNew?: boolean;
    isThereRemoved?: boolean;
    descriptionTooltipLabel?: string;
    accountID?: string;
}

export const RecommendationsViewerSection: React.FC<RecommendationsViewerSectionProps> = ({
    className,
    title,
    uploadView,
    dataType,
    uploadData,
    loading,
    error,
    isThereNew,
    isThereRemoved,
    description,
    tooltipLabel,
    descriptionTooltipLabel,
    accountID,
}) => {
    const columns: ColumnDef<UploadProductDataType>[] = [
        {
            header: ' ',
            id: 'index',
            cell: ({ row }) => (
                <div
                    className={classNames({
                        'text-red-700 relative': row.original.isRemoved,
                        'text-green-700': row.original.isNew,
                    })}
                >
                    {row.index + 1}
                </div>
            ),
        },
        columnHelper.accessor('product_id', {
            header: 'Product',
            cell: info => (
                <div
                    className={classNames({
                        'text-red-700': info.row.original.isRemoved,
                        'text-green-700': info.row.original.isNew,
                    })}
                >
                    {info.getValue()}
                </div>
            ),
        }),
        ...(!uploadView
            ? [
                  columnHelper.accessor('product_name', {
                      header: 'Name',
                      cell: info => (
                          <div
                              className={classNames({
                                  'text-red-700': info.row.original.isRemoved,
                                  'text-green-700': info.row.original.isNew,
                              })}
                          >
                              {info.getValue()}
                          </div>
                      ),
                  }),
              ]
            : []),
        columnHelper.accessor('quantity', {
            header: 'Quantity',
            cell: info => (
                <div
                    className={classNames({
                        'text-red-700': info.row.original.isRemoved,
                        'text-green-700': info.row.original.isNew,
                    })}
                >
                    {info.getValue()}
                </div>
            ),
        }),
    ];

    return (
        <div className={className}>
            <Card
                loading={loading}
                error={
                    !uploadData &&
                    (error?.message
                        ? error?.message
                        : `No recommendations found for account in the ${dataType.toUpperCase()} Tier.`)
                }
                title={
                    <div className="flex w-fit flex-col gap-[10px]">
                        <div className="flex items-center justify-start space-x-2">
                            <Text
                                type="12Mid"
                                className="w-fit rounded-full bg-primaryBlue px-2 py-1 !text-gray-300"
                            >
                                {title}
                            </Text>
                            <NotifyButton
                                section={title}
                                accountID={accountID}
                                className="text-xs"
                            />
                        </div>
                        <Text
                            type="12Reg"
                            className="flex items-center gap-1 !text-[11px] text-navy-solid-50"
                        >
                            {description}
                            {descriptionTooltipLabel?.length > 0 && (
                                <StyledTooltip label={descriptionTooltipLabel} w="70%">
                                    <StyledCopyButton
                                        value={descriptionTooltipLabel}
                                        className="inline-block"
                                        hideIcon
                                    >
                                        <Link size={18} />
                                    </StyledCopyButton>
                                </StyledTooltip>
                            )}
                        </Text>
                    </div>
                }
                tooltipElement={
                    isThereRemoved && (
                        <StyledTooltip label={tooltipLabel}>
                            <Info size={16} className="inline-block text-red-700" />
                        </StyledTooltip>
                    )
                }
                className="h-full"
                innerClassName="h-full"
            >
                <div className="max-h-[340px] overflow-y-auto  md:!max-h-[354px]">
                    <Table<UploadProductDataType>
                        data={uploadData ?? []}
                        columns={columns}
                        noDataMessage="There are no personalized recommendations for this store because it has not made a purchase in the last 60 days."
                        stickyHeader
                        disableHeaderMargin
                    />
                </div>
            </Card>
        </div>
    );
};
