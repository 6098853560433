import React from 'react';
import { Wrench } from 'phosphor-react';
import { useRouter } from 'next/router';
import { Card } from 'andromeda/Card';
import { useRecommendationsData } from './useRecommendationsData';
import { IconWrapperButton } from '../utils/IconWrapperButton';
import { usePersonalizationsQueryParams } from './usePersonalizationsQueryParams';

interface PopupTriggersProps {
    className?: string;
}

export const PopupTriggers: React.FC<PopupTriggersProps> = ({ className }) => {
    const router = useRouter();

    const { selectedNamespace, selectedUseCase } = usePersonalizationsQueryParams();

    const { data, isLoading, error } = useRecommendationsData();

    const contexts = data?.use_case_config?.personalization_contexts;

    const parsedTriggers = contexts
        ?.filter(item => item.title?.toLowerCase().includes('trigger'))
        ?.map(item => ({
            ...item,
            context: JSON.parse(item.context ?? '{}'),
        }));

    const productAddedTrigger = parsedTriggers?.find(
        item => item.title === 'Triggers Product Added',
    )?.context;
    const cartViewedTrigger = parsedTriggers?.find(
        item => item.title === 'Triggers Cart Viewed',
    )?.context;

    return (
        <Card
            className={className}
            loading={isLoading}
            error={error?.message}
            title="Personalized popup triggers"
            actionsElement={
                <IconWrapperButton
                    description="Configurations"
                    onClick={() =>
                        router.push({
                            pathname: '/configurations',
                            query: {
                                namespace: selectedNamespace,
                                usecase: selectedUseCase,
                            },
                        })
                    }
                >
                    <Wrench size={22} />
                </IconWrapperButton>
            }
        >
            {productAddedTrigger && (
                <>
                    Product added trigger <span className="mx-3">|</span> when there are{' '}
                    <strong>{productAddedTrigger.value} unique SKUs</strong> in the cart
                </>
            )}
            {cartViewedTrigger && (
                <div className="mt-3">
                    Cart viewed trigger <span className="mx-3">|</span> when{' '}
                    <strong>{cartViewedTrigger.value} unique SKUs</strong> are added to cart
                </div>
            )}
        </Card>
    );
};
