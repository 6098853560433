import { SPACEPORT_API_URL } from '../../buildConfig/processEnv';
import useApiQuery from '../../hooks/useApiQuery';
import { AccountItem } from './types';

export const getAccountUrl = (namespace: string, accountID: string, limit?: number) =>
    `${SPACEPORT_API_URL}/spaceport/api/personalizer/account?namespace=${namespace}&account_id=${accountID}`;

export const useAccount = ({
    accountID,
    namespace,
    limit,
    disabled,
}: {
    namespace: string;
    accountID: string;
    limit?: number;
    disabled: boolean;
}) =>
    useApiQuery<AccountItem[]>({
        url: getAccountUrl(namespace, accountID, limit),
        disabled: disabled || !namespace || !accountID,
    });
