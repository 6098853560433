import { PersonalizationsHeader } from './PersonalizationsHeader';
import { PersonalizationsContent } from './PersonalizationsContent';
import { PersonalizationsUSPage } from './PersonalizationsUSPage';
import { usePersonalizationsQueryParams } from './usePersonalizationsQueryParams';

export const PersonalizationsPage = () => {
    const { selectedNamespace } = usePersonalizationsQueryParams();
    const isUS = selectedNamespace.includes('US-');

    return isUS ? (
        <PersonalizationsUSPage />
    ) : (
        <>
            <PersonalizationsHeader />
            <PersonalizationsContent />
        </>
    );
};
