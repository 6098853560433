import { SPACEPORT_API_URL } from '../../../buildConfig/processEnv';
import useApiQuery from '../../../hooks/useApiQuery';
import { usePersonalizationsQueryParams } from '../usePersonalizationsQueryParams';

const LAST_WEEKS = 52 * 3; // last three years

export interface BillingDataItem {
    store_id: string;
    product_id: string;
    account_id: string;
    product_name: string;
    quantity: number;
    order_date: string;
    net_revenue: number;
    brand: string;
    category: string;
}

export const useBillingData = (weeks?: number) => {
    const { selectedNamespace, accountID } = usePersonalizationsQueryParams();

    const w = weeks || LAST_WEEKS;

    return useApiQuery<BillingDataItem[]>({
        url: `${SPACEPORT_API_URL}/spaceport/api/console/billing?namespace=${selectedNamespace}&account_id=${accountID}&weeks=${w}`,
        disabled: !selectedNamespace || !accountID,
    });
};
