import useRouterQuery from '../../hooks/useRouterQuery';
import { useUserAllowedNamespaces } from '../../hooks/useUserAllowedNamespaces';
import { useAccountList } from './useAccountList';

interface PersonalizationsQueryParams {
    namespace: string;
    usecase: string;
    accountID: string;
}

export const usePersonalizationsQueryParams = () => {
    const { handleURLParamsChange, router } = useRouterQuery<keyof PersonalizationsQueryParams>();

    const namespacesUserAllowed = useUserAllowedNamespaces();
    const defaultNamespace = namespacesUserAllowed[0] ?? 'PH-NESTLE';
    const selectedNamespace = (router.query.namespace as string) ?? defaultNamespace;

    // `accounts_list` endpoint returns an `account_id` and a `customer_account_id` for each account in the namespace.
    // `account_id` is syonymous of "bees account id" and should not be used in exported_recs endpoint calls
    // `customer_account_id` should instead be used in exported_recs endpoint calls to receive the `store_id`
    // `store_id` is the id of the store that the account is associated with and it should be used for calls to exported_recs endpoint
    const { data: stores } = useAccountList(selectedNamespace);
    const accountID = (router.query.accountID as string) || stores?.[0]?.customer_account_id;

    const selectedUseCase = (router.query.usecase as string) ?? 'QUICK_ORDER';

    const updateSelectedNamespace = (namespace: string) => {
        handleURLParamsChange({
            multipleValues: {
                accountID: undefined,
                namespace,
            },
        });
    };

    const updateSelectedStore = ({ accountId }: { accountId?: string }) => {
        handleURLParamsChange({
            multipleValues: {
                accountID: accountId,
            },
        });
    };

    const updateSelectedUseCase = (useCase: string) => {
        handleURLParamsChange({ key: 'usecase', value: useCase });
    };

    return {
        selectedNamespace,
        selectedUseCase,
        accountID,
        updateSelectedNamespace,
        updateSelectedStore,
        updateSelectedUseCase,
    };
};
