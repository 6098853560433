import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Check } from 'phosphor-react';
import { SPACEPORT_API_URL } from '../../buildConfig/processEnv';
import { generateStoreDataUrl } from '../../hooks/useStoreData';
import { useAppContext } from '../../contexts/appContext';
import { PersonalizationData } from '../../types/Personalization';
import { usePersonalizationsQueryParams } from './usePersonalizationsQueryParams';
import colors from '../../constants/colors';
import { notification } from '../../utils/notification';

interface LikeRecResponse {
    use_case: string;
    namespace: string;
    user_id: string;
    product_id: string;
    user_rating: number;
}

interface MutateFnParams {
    type: 'like' | 'dislike';
    productID: string;
}

export const useLikeRec = (product: string) => {
    const queryClient = useQueryClient();
    const { selectedNamespace, selectedUseCase, accountID } = usePersonalizationsQueryParams();
    const { user } = useAppContext();

    return useMutation<LikeRecResponse, Error, MutateFnParams>({
        mutationFn: async ({ type, productID }) => {
            const thumbs = type === 'like' ? 'thumbs_up' : 'thumbs_down';

            const storeDataKey = [
                generateStoreDataUrl({
                    useCase: selectedUseCase,
                    namespace: selectedNamespace,
                    accountID,
                }),
            ];

            queryClient.cancelQueries({ queryKey: storeDataKey });

            queryClient.setQueryData(storeDataKey, (oldData: PersonalizationData) => {
                if (!oldData) return oldData;

                const newData = {
                    ...oldData,
                    personalization: oldData.personalization.map((item: any) => {
                        if (item.product_id === productID) {
                            return {
                                ...item,
                                user_rating: type === 'like' ? 1 : -1,
                            };
                        }
                        return item;
                    }),
                };
                return newData;
            });

            const url = `${SPACEPORT_API_URL}/spaceport/api/personalizer/${thumbs}/use_case/${selectedUseCase}/namespace/${selectedNamespace}/account_id/${accountID}/product_id/${productID}`;
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${user.siberiaToken}`,
                },
            });

            if (!response.ok) {
                throw new Error('Failed to like recommendation.');
            }

            await queryClient.invalidateQueries({ queryKey: storeDataKey });

            return response.json();
        },
        mutationKey: ['likeRec', product],
        onSuccess: () => {
            notification({
                color: colors.darkGreen,
                icon: <Check />,
                message: 'Feedback recorded.',
            });
        },
    });
};
