import React, { useEffect } from 'react';
import _ from 'lodash';
import { GlobeHemisphereWest } from 'phosphor-react';
import Select from 'andromeda/selects/select';
import { useRouter } from 'next/router';
import { DropdownOption } from '../../andromeda/Dropdown';
import { CONSOLE_MODULE_NAME_MAP } from '../console/ConsoleModule';
import { useNamespaceList } from '../../hooks/useNamespaceList';
import { useUserAllowedNamespaces } from '../../hooks/useUserAllowedNamespaces';
import { useUserAccess } from '../../hooks/useUserAccess';

export const formatUseCaseName = (useCase: string) =>
    CONSOLE_MODULE_NAME_MAP[useCase] ?? _.startCase(_.lowerCase(useCase));

interface NamespaceDropdownProps {
    selectedNamespace: string;
    updateSelectedNamespace: (value: string) => void;
    namespaces?: string[];
    label?: string;
    clearable?: boolean;
}

export const NamespaceDropdown: React.FC<NamespaceDropdownProps> = ({
    selectedNamespace,
    updateSelectedNamespace,
    namespaces,
    label,
    clearable,
}) => {
    const router = useRouter();
    const { data: namespaceData } = useNamespaceList(!!namespaces);
    const namespacesUserAllowed = useUserAllowedNamespaces();
    const isArenaStaff = useUserAccess({ userGroupWhitelist: 'arena-staff' });

    const namespaceOptions: DropdownOption[] = (() => {
        if (!namespaces) {
            return namespaceData
                ?.filter(
                    namespace => namespacesUserAllowed.includes(namespace.name) || isArenaStaff,
                )
                ?.sort((a, b) => a.name.localeCompare(b.name))
                ?.map(namespace => ({
                    value: namespace.name,
                    label: namespace.name,
                }));
        }
        return namespaces.map(namespace => ({
            value: namespace,
            label: namespace,
        }));
    })();

    useEffect(() => {
        if (
            selectedNamespace ||
            !router.isReady ||
            router.query?.namespace?.length > 0 ||
            clearable
        )
            return;
        updateSelectedNamespace(namespacesUserAllowed[0] ?? 'DE');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [namespacesUserAllowed, selectedNamespace, updateSelectedNamespace]);

    return (
        <div className="flex items-center gap-4">
            <div className="flex items-center gap-1">
                <Select
                    data={namespaceOptions ?? []}
                    value={selectedNamespace ?? 'All'}
                    onChange={updateSelectedNamespace}
                    label={
                        <div className="flex h-10 items-center gap-1 text-base text-blue-600">
                            <GlobeHemisphereWest size={20} className="mt-0.5" />
                            {label ?? 'Namespace:'}
                        </div>
                    }
                    cleanDesign
                    searchable={false}
                    width={120}
                    clearable={clearable}
                    placeholder="select a namespace"
                    defaultValue="All"
                />
            </div>
        </div>
    );
};
