import { useQuery } from '@tanstack/react-query';
import { useAppContext } from '../../contexts/appContext';
import { SPACEPORT_API_URL } from '../../buildConfig/processEnv';

const useWholesalerProductData = () => {
    const { user } = useAppContext();

    const postRequest = async () => {
        try {
            const requestOptions: any = {
                headers: {
                    accept: 'application/json',
                    authorization: `Bearer ${user.siberiaToken}`,
                },
                method: 'GET',
                credentials: 'include',
            };
            const url = `${SPACEPORT_API_URL}/spaceport/api/personalizer/personalize/namespace/US-WILSBACH/products`;
            const response = await fetch(url, requestOptions);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const body = await response.json();
            return body;
        } catch (error) {
            throw new Error('An error occurred while making the post request');
        }
    };

    return useQuery({ queryKey: ['useWholesalerProductData'], queryFn: postRequest });
};

export default useWholesalerProductData;
