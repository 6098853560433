import { SPACEPORT_API_URL } from '../../buildConfig/processEnv';
import useApiQuery from '../../hooks/useApiQuery';

type Column = {
    column_name: string;
    type: string;
    is_partition_column: null;
};

export const getModelOutputSchemaUrl = (usecase: string, namespace: string) =>
    `${SPACEPORT_API_URL}/spaceport/api/model_output/model_output_schema/use_case/${usecase}/namespace/${namespace}`;

export const useModelOutputSchema = (usecase: string, namespace: string) =>
    useApiQuery<Column[]>({
        url: getModelOutputSchemaUrl(usecase, namespace),
        disabled: !namespace || !usecase,
    });
