import { useCallback } from 'react';
import useRouterQuery from '../../hooks/useRouterQuery';
import { useUserAllowedNamespaces } from '../../hooks/useUserAllowedNamespaces';

type ConfigurationQueryKey = 'namespace' | 'usecase' | 'vendor';

export const useConfigurationsQueryParams = () => {
    const { router, handleURLParamsChange } = useRouterQuery<ConfigurationQueryKey>();

    const namespacesUserAllowed = useUserAllowedNamespaces();

    const selectedNamespace =
        (router.query.namespace as string) ?? namespacesUserAllowed?.[0] ?? 'PH-NESTLE';
    const selectedUseCase = (router.query.usecase as string) ?? 'QUICK_ORDER';
    const selectedVendor = router.query?.vendor as string;

    const updateSelectedNamespace = useCallback(
        (namespace: string) => {
            handleURLParamsChange({
                key: 'namespace',
                value: namespace,
                extraCleaning: ['vendor', 'usecase'],
            });
        },
        [handleURLParamsChange],
    );
    const updateSelectedVendor = useCallback(
        (vendor: string) => {
            handleURLParamsChange({ key: 'vendor', value: vendor });
        },
        [handleURLParamsChange],
    );

    const updateSelectedUseCase = useCallback(
        (useCase: string) => {
            handleURLParamsChange({ key: 'usecase', value: useCase, extraCleaning: ['vendor'] });
        },
        [handleURLParamsChange],
    );

    return {
        selectedNamespace,
        selectedUseCase,
        selectedVendor,
        updateSelectedNamespace,
        updateSelectedUseCase,
        updateSelectedVendor,
    };
};
