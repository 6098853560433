import { Button, CopyButton } from '@mantine/core';
import classNames from 'classnames';
import { Check, ClipboardText } from 'phosphor-react';
import React from 'react';
import colors from '../../constants/colors';

const StyledCopyButton = React.forwardRef(
    (
        {
            value,
            className,
            size = 18,
            children,
            hideIcon,
        }: {
            value: string;
            className?: string;
            size?: number;
            children?: string | JSX.Element;
            hideIcon?: boolean;
        },
        ref: React.Ref<HTMLDivElement>,
    ) => (
        <div ref={ref}>
            <CopyButton value={value}>
                {({ copied, copy }) => (
                    <Button
                        color={copied ? 'teal' : 'blue'}
                        loading={false}
                        onClick={copy}
                        unstyled
                        style={{
                            color: copied ? colors.green : 'currentcolor',
                        }}
                        className={classNames(
                            className,
                            'flex justify-center items-center hover:bg-shadow-300',
                        )}
                        classNames={{ label: 'flex gap-1 items-center' }}
                    >
                        {!hideIcon &&
                            (!copied ? (
                                <ClipboardText size={size} strokeWidth={1} />
                            ) : (
                                <Check size={size} weight="thin" />
                            ))}
                        {children}
                    </Button>
                )}
            </CopyButton>
        </div>
    ),
);

export default StyledCopyButton;
