import { SPACEPORT_API_URL } from '../buildConfig/processEnv';
import { PersonalizationData } from '../types/Personalization';
import useApiQuery from './useApiQuery';

interface UseStoreDataParams {
    useCase: string;
    namespace?: string;
    accountID?: string;
}

export const generateStoreDataUrl = ({ useCase, namespace, accountID }: UseStoreDataParams) =>
    `${SPACEPORT_API_URL}/spaceport/api/exports/exported_recs/use_case/${useCase}/namespace/${namespace}/account_id/${accountID}/`;

export const useStoreData = ({ useCase, namespace, accountID }: UseStoreDataParams) => {
    const url = generateStoreDataUrl({ useCase, namespace, accountID });

    return useApiQuery<PersonalizationData>({
        url,
        disabled: !namespace || !accountID,
    });
};
