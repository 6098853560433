import { useQuery } from '@tanstack/react-query';
import { useAppContext } from '../../contexts/appContext';
import { IS_PROD, SPACEPORT_API_URL } from '../../buildConfig/processEnv';

const useWholesalerTable = (useCase: string, parameter: string, percentParameter: string) => {
    const { user } = useAppContext();
    const table =
        useCase === 'Upsell'
            ? `us_wilsbach_${IS_PROD ? 'prod' : 'uat'}_cross_sell_up_sell.recommendations_with_products_data`
            : `us_wilsbach_${IS_PROD ? 'prod' : 'uat'}_quick_order.recommendations_with_products_data`;

    const postRequest = async () => {
        try {
            const requestOptions: any = {
                headers: {
                    accept: 'application/json',
                    authorization: `Bearer ${user.siberiaToken}`,
                },
                method: 'POST',
                credentials: 'include',
                body: JSON.stringify({
                    group_by_options: ['rank', parameter],
                    output_options: [percentParameter],
                }),
            };
            const url = `${SPACEPORT_API_URL}/spaceport/api/inspect/query_table/${table}/namespace/US-WILSBACH`;
            const response = await fetch(url, requestOptions);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const body = await response.json();
            return body;
        } catch (error) {
            throw new Error('An error occurred while making the post request');
        }
    };

    return useQuery({
        queryKey: ['useWholesalerTable', useCase, parameter, percentParameter],
        queryFn: postRequest,
    });
};

export default useWholesalerTable;
