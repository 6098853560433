import { Calendar } from 'phosphor-react';
import React, { useMemo, useState } from 'react';
import Select from 'andromeda/selects/select';
import { formateDateRangeValueForDisplay } from '../../../utils/dateHelpers';
import { primaryColors } from '../../../constants/colors';

function WeeksPicker({
    handleChange,
    disabled,
    fullLabel,
    defaultValue = undefined,
}: {
    handleChange: (weeks: number) => void;
    disabled?: boolean;
    fullLabel?: string;
    defaultValue?: undefined | 1 | 4 | 12 | 24 | 52;
}) {
    const [value, setValue] = useState<number>(defaultValue);

    const defaultLabel = fullLabel || 'Full purchase history';
    const durations = useMemo(
        () => [
            { value: defaultLabel, label: defaultLabel },
            { value: '1', label: 'Last 7 Days' },
            { value: '4', label: 'Last 30 Days' },
            { value: '12', label: 'Last 3 Months' },
            { value: '24', label: 'Last 6 Months' },
            { value: '52', label: 'Last 12 Months' },
        ],
        [defaultLabel],
    );

    return (
        <Select
            leftSection={<Calendar size={24} color={primaryColors.blue} />}
            data={durations}
            value={(value && value.toString()) || defaultLabel}
            onChange={e => {
                setValue(e.match(/[0-9]/g) ? Number(e) : undefined);
                handleChange(e.match(/[0-9]/g) ? Number(e) : undefined);
            }}
            checkIcon
            defaultValue={defaultLabel}
            cleanDesign
            searchable={false}
            disabled={disabled}
            width={213}
        />
    );
}

export default WeeksPicker;
export { formateDateRangeValueForDisplay };
