import React, { useMemo, useRef, useState } from 'react';
import { Rocket, ThumbsUp } from 'phosphor-react';
import { Button, Modal, Tabs, Textarea } from '@mantine/core';
import classNames from 'classnames';
import MultiSelect from 'andromeda/selects/multiSelect';
import { SpaceportCommonHeader } from '../utils/SpaceportCommonHeader';
import { Button as AndromedaButton } from '../../andromeda/Button';
import { PersonalizationsUSWholesaler } from './PersonalizationsUSWholesaler';
import { PersonalizationsContent } from './PersonalizationsContent';
import { ModuleDropdown } from './ModuleDropdown';
import { NamespaceDropdown } from '../utils/NamespaceDropdown';
import { usePersonalizationsQueryParams } from './usePersonalizationsQueryParams';
import useWholesalerProductData from '../configurationsV2/useWholesalerProductData';
import { useSendFeedback } from './useSendFeedback';

const TYPES = [
    { title: 'All store types', val: 'all' },
    { title: 'On-premise stores', val: 'on' },
    { title: 'Off-premise stores', val: 'off' },
];
const LEVELS = [
    { title: 'Do not recommend', val: 'not' },
    { title: 'Recommend less', val: 'less' },
    { title: 'Recommend more', val: 'more' },
];

export const PersonalizationsUSPage = () => {
    const [activeTab, setActiveTab] = useState('wholesaler');
    const [openModal, setOpenModal] = useState(false);
    const [searchValue, setSearchValue] = useState([]);
    const { selectedNamespace, updateSelectedNamespace } = usePersonalizationsQueryParams();
    const { data, isLoading } = useWholesalerProductData();
    const [selectedType, setSelectedType] = useState('all');
    const [selectedLevel, setSelectedLevel] = useState('not');
    const [textArea, setTextArea] = useState('');
    const { mutate, isPending: isLoadingUpdate } = useSendFeedback(selectedNamespace, setOpenModal);
    const modalData: any[] = useMemo(
        () =>
            Array.from(
                new Set(
                    data?.flatMap(row => [
                        ...(row.brand ? [row.brand] : []),
                        ...(row.product_name ? [row.product_name] : []),
                    ]),
                ),
            ).sort(),
        [data],
    );

    const sendFeedback = () => {
        const form = {
            products: searchValue,
            type: selectedType,
            level: selectedLevel,
            feedback: textArea,
            setOpenModal,
        };
        mutate(form);
    };

    const ref = useRef<HTMLDivElement>();
    return (
        <>
            <SpaceportCommonHeader
                title="Personalizations"
                actionsElement={
                    <div className="flex flex-row items-center gap-3 overflow-auto">
                        <NamespaceDropdown
                            selectedNamespace={selectedNamespace}
                            updateSelectedNamespace={updateSelectedNamespace}
                        />
                        {activeTab === 'poc' && <ModuleDropdown />}
                        <AndromedaButton onClick={() => setOpenModal(true)}>
                            <ThumbsUp size={16} className="mr-1" /> Give model feedback
                        </AndromedaButton>
                    </div>
                }
                className="!pb-0"
            />
            <Tabs
                defaultValue="wholesaler"
                classNames={{
                    list: 'before:!border-none',
                    tab: 'hover:text-primary-60 !border-none rounded-lg border-transparent opacity-25 text-sm !bg-transparent disabled:text-navy-solid-70 disabled:hover:!text-navy-solid-70 disabled:!opacity-100',
                }}
                value={activeTab}
                onChange={(tab: string) => {
                    setActiveTab(tab);
                }}
            >
                <Tabs.List className="flex flex-row gap-2 py-4">
                    <Tabs.Tab
                        disabled={activeTab === 'wholesaler'}
                        className="disabled:cursor-default"
                        value="wholesaler"
                    >
                        Wholesaler view
                    </Tabs.Tab>
                    <Tabs.Tab
                        disabled={activeTab === 'poc'}
                        className="disabled:cursor-default"
                        value="poc"
                    >
                        Store view
                    </Tabs.Tab>
                </Tabs.List>
                <Tabs.Panel value="wholesaler">
                    <PersonalizationsUSWholesaler />
                </Tabs.Panel>
                <Tabs.Panel value="poc">
                    <PersonalizationsContent />
                </Tabs.Panel>
            </Tabs>
            <Modal.Root
                opened={openModal}
                onClose={() => {
                    setOpenModal(false);
                }}
                size="800px"
                transitionProps={{ transition: 'rotate-left' }}
                centered
                ref={ref}
            >
                <Modal.Overlay />
                <Modal.Content className="p-2" ref={ref}>
                    <Modal.Header className="border-spacing-3 border-b border-shadow-600">
                        <div className="flex flex-col">
                            <Modal.Title>Give model feedback</Modal.Title>
                            <div className="mt-2 text-xs font-extralight text-navy-solid-30">
                                Suggest products to be incorporated into the model and recommended
                                to stores you specify
                            </div>
                        </div>
                        <Modal.CloseButton />
                    </Modal.Header>
                    <Modal.Body>
                        <div className="mt-6 flex flex-col gap-6 text-xs">
                            <div className="flex w-full flex-col gap-1">
                                Select a product or a brand
                                <MultiSelect
                                    onChange={e => setSearchValue(e)}
                                    value={searchValue}
                                    data={modalData}
                                    placeholder="Select a product or a brand"
                                    loading={isLoading}
                                    clearable
                                    check
                                    width={400}
                                />
                            </div>

                            <div>
                                Select a store type
                                <div className="mt-1 flex flex-row items-center justify-between">
                                    {TYPES.map(row => (
                                        <Button
                                            key={row.title}
                                            loading={false}
                                            className={classNames({
                                                'bg-primary-10': selectedType === row.val,
                                            })}
                                            variant="subtle"
                                            onClick={() => setSelectedType(row.val)}
                                        >
                                            {row.title}
                                        </Button>
                                    ))}
                                </div>
                            </div>
                            <div>
                                Recommendation level
                                <div className="mt-1 flex flex-row items-center justify-between">
                                    {LEVELS.map(row => (
                                        <Button
                                            key={row.title}
                                            loading={false}
                                            className={classNames({
                                                'bg-primary-10': selectedLevel === row.val,
                                            })}
                                            variant="subtle"
                                            onClick={() => setSelectedLevel(row.val)}
                                        >
                                            {row.title}
                                        </Button>
                                    ))}
                                </div>
                            </div>
                            <div>
                                Additional feedback
                                <Textarea
                                    size="lg"
                                    radius="md"
                                    className="mt-1"
                                    value={textArea}
                                    onChange={v => setTextArea(v.currentTarget.value)}
                                />
                            </div>
                            <AndromedaButton onClick={sendFeedback}>
                                <Rocket className="mr-1 rotate-45" size={16} /> Submit model
                                feedback
                            </AndromedaButton>
                        </div>
                    </Modal.Body>
                </Modal.Content>
            </Modal.Root>
        </>
    );
};
