import { useStoreData } from '../../hooks/useStoreData';
import { usePersonalizationsQueryParams } from './usePersonalizationsQueryParams';

export const useRecommendationsData = () => {
    const { selectedNamespace, selectedUseCase, accountID } = usePersonalizationsQueryParams();

    return useStoreData({
        accountID,
        useCase: selectedUseCase,
        namespace: selectedNamespace,
    });
};
