import cx from 'classnames';
import React, { ButtonHTMLAttributes, PropsWithChildren } from 'react';
import StyledTooltip from './styledTooltip';

interface IconWrapperButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    description?: string;
    width?: number;
}

export const IconWrapperButton: React.FC<PropsWithChildren<IconWrapperButtonProps>> = ({
    description,
    width,
    className,
    ...props
}) => (
    <StyledTooltip
        w={width}
        label={description}
        disabled={!description}
        position="bottom"
        classNames={{ tooltip: '!capitalize' }}
        withinPortal
    >
        <span className="flex items-center justify-center">
            <button
                {...props}
                type="button"
                className={cx(
                    'text-primary-70 hover:!text-primary fill-primary-70 hover:fill-primary transition-colors',
                    {
                        'hover:!text-primary-70 cursor-not-allowed': props.disabled,
                    },
                    className,
                )}
            />
        </span>
    </StyledTooltip>
);
