import { DownloadSimple } from 'phosphor-react';
import HeaderActionsWrapper from '@/responsive/HeaderActionsWrapper';
import { ModuleDropdown } from './ModuleDropdown';
import { SpaceportCommonHeader } from '../utils/SpaceportCommonHeader';
import { IconWrapperButton } from '../utils/IconWrapperButton';
import { usePersonalizationsQueryParams } from './usePersonalizationsQueryParams';
import { NamespaceDropdown } from '../utils/NamespaceDropdown';

const ActionsElement = () => {
    const { selectedNamespace, updateSelectedNamespace } = usePersonalizationsQueryParams();
    return (
        <HeaderActionsWrapper>
            <NamespaceDropdown
                selectedNamespace={selectedNamespace}
                updateSelectedNamespace={updateSelectedNamespace}
            />
            <ModuleDropdown />
            <IconWrapperButton type="button" disabled description="Coming soon">
                <DownloadSimple size={22} />
            </IconWrapperButton>
        </HeaderActionsWrapper>
    );
};

export const PersonalizationsHeader = () => (
    <SpaceportCommonHeader
        title="Personalizations"
        description="View what the selected store is currently seeing in BEES."
        actionsElement={<ActionsElement />}
    />
);
