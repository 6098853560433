import React, { useMemo, useState } from 'react';
import _ from 'lodash';
import dynamic from 'next/dynamic';
import classNames from 'classnames';
import { ArrowRight } from 'phosphor-react';
import { BareCard } from 'andromeda/Card';
import { fusionMoment } from '@/utils/dateHelpers';
import { StoreSelection } from './StoreSelection';
import { RecommendationsSection } from './RecommendationsSection';
import { ContextualText } from './ContextualText';
import { PopupTriggers } from './PopupTriggers';
import { ContextualImage } from './ContextualImage';
import { useActiveUseCaseConfig } from '../configurationsV2/useActiveUseCaseConfig';
import { usePersonalizationsQueryParams } from './usePersonalizationsQueryParams';
import { RecommendationsViewerSection } from './RecommendationsViewerSection';
import { useRecommendationsData } from './useRecommendationsData';
import { useUploadData } from '../../hooks/useUploadData';
import { useAccountList } from './useAccountList';

const BillingSection = dynamic(() => import('./BillingSection').then(file => file.BillingSection), {
    ssr: false,
});

export const PersonalizationsContent = () => {
    const { selectedUseCase, selectedNamespace, accountID } = usePersonalizationsQueryParams();
    const { data: ActiveDataCase } = useActiveUseCaseConfig(selectedNamespace);
    const [selectedLanguage, setSelectedLanguage] = useState(
        selectedNamespace.includes('US-') ? 'en-US' : '',
    );
    const [billingSkuSearch, setBillingSkuSearch] = useState<string[]>([]);
    const [showDebug, setShowDebug] = useState<boolean>(false);
    const { isLoading: storesLoading } = useAccountList(selectedNamespace);
    const {
        data: personalizationData,
        error: recsError,
        isLoading: personalizationLoading,
    } = useRecommendationsData();

    const vendorAccountID = personalizationData?.personalization?.[0]?.vendor_account_id;
    const {
        data: personalizationReceivedData,
        isLoading: personalizationReceivedLoading,
        error: personalizationReceivedError,
    } = useUploadData({
        namespace: selectedNamespace,
        usecase: selectedUseCase,
        recsTier: 'data',
        vendorAccountID,
        disabled: !vendorAccountID,
    });

    const {
        data: personalizationSeenData,
        isLoading: personalizationSeenLoading,
        error: personalizationSeenError,
    } = useUploadData({
        namespace: selectedNamespace,
        usecase: selectedUseCase,
        recsTier: 'business',
        vendorAccountID,
        disabled: !vendorAccountID,
    });

    const {
        personalizationReceivedDataChecked,
        isPersonalizationReceivedDataNew,
        isPersonalizationReceivedDataRemoved,
        personalizationSeenDataChecked,
        isPersonalizationSeenDataRemoved,
        isPersonalizationSeenDataNew,
    } = useMemo(() => {
        const personalizationDataIDs =
            personalizationData?.personalization?.map(prod => prod.product_id) ?? [];
        const personalizationReceivedDataIDs =
            personalizationReceivedData?.products?.map(prod => prod.product_id) ?? [];
        const personalizationSeenDataIDs =
            personalizationSeenData?.products?.map(prod => prod.product_id) ?? [];

        const isPersonalizationReceivedDataRemovedCheck =
            personalizationReceivedData?.products.reduce(
                (acc, prod) => ({
                    seen:
                        acc.seen ||
                        (!personalizationSeenDataIDs.includes(prod.product_id) &&
                            personalizationDataIDs.includes(prod.product_id)),
                    recs:
                        acc.recs ||
                        (personalizationSeenDataIDs.includes(prod.product_id) &&
                            !personalizationDataIDs.includes(prod.product_id)),
                }),
                {
                    seen: false,
                    recs: false,
                },
            );

        const isPersonalizationReceivedDataNewCheck = personalizationReceivedData?.products.some(
            prod =>
                !personalizationSeenDataIDs.includes(prod.product_id) &&
                personalizationDataIDs.includes(prod.product_id),
        );
        const isPersonalizationSeenDataRemovedCheck = personalizationReceivedData?.products.some(
            prod =>
                personalizationReceivedDataIDs.includes(prod.product_id) &&
                !personalizationDataIDs.includes(prod.product_id),
        );
        const isPersonalizationSeenDataNewCheck = personalizationReceivedData?.products.some(
            prod =>
                !personalizationReceivedDataIDs.includes(prod.product_id) &&
                personalizationDataIDs.includes(prod.product_id),
        );
        return {
            personalizationReceivedDataChecked: personalizationReceivedData?.products.map(prod => ({
                ...prod,
                isRemoved:
                    !personalizationSeenDataIDs.includes(prod.product_id) &&
                    personalizationDataIDs.includes(prod.product_id),
                isNew: false,
                // isNew:
                //     personalizationSeenDataIDs.includes(prod.product_id) &&
                //     !personalizationDataIDs.includes(prod.product_id),
            })),
            isPersonalizationReceivedDataRemoved: isPersonalizationReceivedDataRemovedCheck,
            isPersonalizationReceivedDataNew: isPersonalizationReceivedDataNewCheck,
            personalizationSeenDataChecked: personalizationSeenData?.products.map(prod => ({
                ...prod,
                isRemoved:
                    !personalizationReceivedDataIDs.includes(prod.product_id) &&
                    personalizationDataIDs.includes(prod.product_id),
                isNew: false,
                // isNew:
                //     personalizationReceivedDataIDs.includes(prod.product_id) &&
                //     !personalizationDataIDs.includes(prod.product_id),
            })),
            isPersonalizationSeenDataRemoved: isPersonalizationSeenDataRemovedCheck,
            isPersonalizationSeenDataNew: isPersonalizationSeenDataNewCheck,
        };
    }, [
        personalizationData,
        personalizationReceivedData?.products,
        personalizationSeenData?.products,
    ]);

    return (
        <div className="flex w-full flex-col gap-4 overflow-x-hidden pb-24">
            <StoreSelection showDebug={showDebug} setShowDebug={setShowDebug} />

            <div
                className={classNames('gap-4 mt-6', {
                    'grid grid-cols-1 lg:grid-cols-2': !showDebug,
                    'flex items-center max-lg:flex-wrap overflow-auto': showDebug,
                })}
            >
                <RecommendationsSection
                    className={classNames('max-h-[474.8px] h-[474.8px]', {
                        'w-full min-w-[420px] lg:w-3/6': showDebug,
                        'col-span-1': !showDebug,
                    })}
                    updateBillingSkuSearch={setBillingSkuSearch}
                    showDebug={showDebug}
                    accountID={accountID}
                    loading={storesLoading || personalizationLoading}
                />
                <div
                    className={classNames('flex gap-3', {
                        'col-span-1 flex-col': !showDebug,
                        'items-center w-full max-lg:flex-col': showDebug,
                    })}
                >
                    {showDebug && (
                        <>
                            <ArrowRight size={22} className="min-w-[22px] max-lg:rotate-90" />
                            <RecommendationsViewerSection
                                className="h-[474.8px] max-h-[474.8px] w-full min-w-[45%]"
                                title="Step 2: Received by BEES"
                                description={`These are the recommendations that exist in the BEES Microservice - Data tier. last updated ${fusionMoment(personalizationReceivedData?.date).format('D MMM YYYY')}.`}
                                descriptionTooltipLabel={
                                    personalizationReceivedData?.url ??
                                    personalizationReceivedError?.['url']
                                }
                                uploadView
                                dataType="data"
                                uploadData={personalizationReceivedDataChecked}
                                loading={
                                    storesLoading ||
                                    personalizationLoading ||
                                    personalizationReceivedLoading
                                }
                                error={personalizationReceivedError}
                                isThereRemoved={
                                    isPersonalizationReceivedDataRemoved?.recs ||
                                    isPersonalizationReceivedDataRemoved?.seen
                                }
                                isThereNew={isPersonalizationReceivedDataNew}
                                tooltipLabel={`Recommendations were dropped in the ${isPersonalizationReceivedDataRemoved?.recs ? 'previous' : 'next'} step`}
                                accountID={accountID}
                            />
                            <ArrowRight size={22} className="min-w-[22px] max-lg:rotate-90" />
                            <RecommendationsViewerSection
                                className="h-[474.8px] max-h-[474.8px] w-full min-w-[45%]"
                                title="Step 3: Visible on the BEES App"
                                uploadView={false}
                                dataType="business"
                                description={`These are the recommendations that exist in the BEES Microservice - Business tier. last updated ${fusionMoment(personalizationSeenData?.date).format('D MMM YYYY')}.`}
                                descriptionTooltipLabel={
                                    personalizationSeenData?.url ??
                                    personalizationSeenError?.['url']
                                }
                                loading={
                                    storesLoading ||
                                    personalizationLoading ||
                                    personalizationSeenLoading
                                }
                                error={personalizationSeenError}
                                uploadData={personalizationSeenDataChecked}
                                isThereRemoved={isPersonalizationSeenDataRemoved}
                                isThereNew={isPersonalizationSeenDataNew}
                                tooltipLabel="Recommendations were dropped from the previous step"
                                accountID={accountID}
                            />
                        </>
                    )}
                    {selectedUseCase === 'DEAL_SORT' && !showDebug && (
                        <BareCard className="rounded !pb-14">
                            Please note that there are no personalized contextual configurations for
                            Deal Sort.
                        </BareCard>
                    )}
                    {selectedUseCase !== 'DEAL_SORT' && !showDebug && (
                        <>
                            <ContextualText
                                selectedLanguage={selectedLanguage}
                                setSelectedLanguage={setSelectedLanguage}
                            />
                            {selectedUseCase === 'QUICK_ORDER' && (
                                <BareCard className="rounded !pb-14">
                                    Please note that there are no additional personalized contextual
                                    configurations for Suggested order.
                                </BareCard>
                            )}
                            {selectedUseCase === 'CROSS_SELL_UP_SELL' && <PopupTriggers />}
                        </>
                    )}
                </div>
            </div>
            {['CROSS_SELL_UP_SELL', 'FEATURED_OFFERS'].includes(selectedUseCase) && (
                <div className="mt-3 grid grid-cols-2 gap-3">
                    <ContextualImage
                        platform="APP"
                        useCaseConfig={ActiveDataCase}
                        language={selectedLanguage}
                    />
                    <ContextualImage
                        platform="WEB"
                        useCaseConfig={ActiveDataCase}
                        language={selectedLanguage}
                    />
                </div>
            )}
            <BillingSection
                billingSkuSearch={billingSkuSearch}
                updateBillingSkuSearch={setBillingSkuSearch}
            />
        </div>
    );
};
