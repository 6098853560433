import { UseCaseDropdown } from '../utils/UseCaseDropdown';
import { usePersonalizationsQueryParams } from './usePersonalizationsQueryParams';

export const ModuleDropdown = () => {
    const { updateSelectedUseCase, selectedUseCase, selectedNamespace } =
        usePersonalizationsQueryParams();

    return (
        <UseCaseDropdown
            selectedModule={selectedUseCase}
            updateSelectedModule={updateSelectedUseCase}
            selectedNamespace={selectedNamespace}
        />
    );
};
