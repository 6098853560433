import styled from '@emotion/styled';
import React, { PropsWithChildren } from 'react';

interface GradientBgProps {
    colors: string[];
}

export const GradientBg = styled.div<GradientBgProps>`
    background: linear-gradient(90deg, ${props => props.colors.join(', ')});
`;

type GradientBadgeProps = GradientBgProps;

export const GradientBadge: React.FC<PropsWithChildren<GradientBadgeProps>> = ({
    children,
    colors,
}) => (
    <GradientBg colors={colors} className="rounded-full p-px">
        <div className="rounded-full bg-secondary">
            <GradientBg
                colors={colors}
                className="!bg-clip-text px-2 py-1 text-xs font-semibold text-transparent"
            >
                {children}
            </GradientBg>
        </div>
    </GradientBg>
);
