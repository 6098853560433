import React, { useMemo, useState } from 'react';
import _ from 'lodash';
import { Indicator, Tooltip } from '@mantine/core';
import { X } from 'phosphor-react';
import Select from 'andromeda/selects/select';
import { NewTypeSelectValues } from 'andromeda/types/select/newTypeOfSelect';
import WeeksPicker from './BillingSection/WeeksPicker';
import { ChartContainer } from '../console/experiments/DMS/ChartContainer';
import BarChart from '../utils/charts/barChart';
import useWholesalerTable from '../configurationsV2/useWholesalerTable';
import { formatNumber } from '../../utils/numberFormatHelpers';
import MultiLevelSelect from '../../andromeda/multiSelect/multiLevelSelect';
import { usWholesalerColors } from '../../constants/colors';

type ChartDataType = { x: string; y: number }[];
export const PersonalizationsUSWholesaler = () => {
    const [value, setValue] = useState<'Suggested Order' | 'Upsell'>('Suggested Order');
    const [filters, setFilters] = useState<NewTypeSelectValues>({});
    const [weeks, setWeeks] = useState<number>();
    const { data, isLoading } = useWholesalerTable(value, 'is_abi', 'percent_is_abi');
    const { data: brandData, isLoading: brandLoading } = useWholesalerTable(
        value,
        'brand',
        'percent_brands_recommended',
    );
    const brands: any[] = useMemo(
        () => [...new Set(brandData?.map(row => String(row?.brand))?.sort())],
        [brandData],
    );

    const chartData = useMemo(() => {
        const searchValues: any = filters?.Brands?.value || [];
        const filteredBrandObject = {};
        if (searchValues?.length > 0) {
            brandData
                ?.filter(row => searchValues?.includes(String(row?.brand)))
                ?.sort((a, b) => Number(a?.rank) - Number(b?.rank))
                ?.forEach(row => {
                    if (!filteredBrandObject[row?.brand]) {
                        filteredBrandObject[row?.brand] = [row];
                    } else {
                        filteredBrandObject[row?.brand].push(row);
                    }
                });
        }

        const filteredData = { ABI: [], nonABI: [] };
        data
            ?.sort((a, b) => Number(a?.rank) - Number(b?.rank))
            ?.forEach(row => {
                if (String(row?.['rank']).split('').includes('.')) {
                    return;
                }
                if (row?.['is_abi']) {
                    filteredData.ABI.push(row);
                } else {
                    filteredData.nonABI.push(row);
                }
            });
        Object.keys(filteredBrandObject).forEach(key => {
            filteredData.ABI.forEach(row => {
                const currentRow = filteredBrandObject[key].find(
                    brandRow => brandRow?.rank === row?.rank,
                );
                if (!currentRow) {
                    filteredBrandObject[key].push({
                        rank: row?.rank,
                        brand: key,
                        '%_brand': currentRow?.rank === row?.rank ? currentRow?.['%_brand'] : 0,
                    });
                }
            });
        });

        return searchValues?.length < 1
            ? [
                  {
                      label: `Non-ABI`,
                      borderWidth: 1,
                      borderColor: '#BB54B5',
                      backgroundColor: '#BB54B5',
                      opacity: 0.5,
                      data: Object.values(
                          filteredData.ABI.reduce((acc, row) => {
                              const x = `Position ${row?.rank}`;
                              return {
                                  ...acc,
                                  [x]: {
                                      x,
                                      y: (acc?.[x]?.y ?? 0) + (row?.['%_is_abi'] ?? 0),
                                  },
                              };
                          }, {}),
                      ) as ChartDataType,
                  },
                  {
                      label: `ABI`,
                      borderWidth: 1,
                      borderColor: '#345DEE',
                      backgroundColor: '#345DEE',
                      opacity: 0.5,
                      data: Object.values(
                          filteredData.nonABI.reduce((acc, row) => {
                              const x = `Position ${row?.rank}`;
                              return {
                                  ...acc,
                                  [x]: {
                                      x,
                                      y: (acc?.[x]?.y ?? 0) + (row?.['%_is_abi'] ?? 0),
                                  },
                              };
                          }, {}),
                      ) as ChartDataType,
                  },
              ]
            : Object.keys(filteredBrandObject).map((key, i) => ({
                  label: key,
                  borderWidth: 1,
                  borderColor: usWholesalerColors[i % usWholesalerColors.length],
                  backgroundColor: usWholesalerColors[i % usWholesalerColors.length],
                  opacity: 0.5,
                  data: Object.values(
                      filteredBrandObject[key]
                          .sort((a, b) => Number(a?.rank) - Number(b?.rank))
                          .reduce((acc, row) => {
                              const x = `Position ${row?.rank}`;
                              return {
                                  ...acc,
                                  [x]: {
                                      x,
                                      y: (acc?.[x]?.y ?? 0) + (row?.['%_brand'] ?? 0),
                                  },
                              };
                          }, {}),
                  ) as ChartDataType,
              }));
    }, [brandData, data, filters]);

    return (
        <div className="w-full overflow-x-hidden pb-24">
            <div className="flex flex-row items-center justify-between">
                <div>
                    <Select
                        data={['Suggested Order', 'Upsell']}
                        value={value}
                        onChange={(v: 'Suggested Order' | 'Upsell') => setValue(v)}
                        checkIcon
                    />
                </div>
                <div className="flex flex-row items-center gap-3">
                    <div className="flex flex-row items-center">
                        {filters?.Brands?.value?.length > 1 && (
                            <Tooltip label="Deselect All">
                                <div>
                                    <X
                                        onClick={() => setFilters({})}
                                        className="mr-1 cursor-pointer rounded-lg p-1 hover:bg-primary-3"
                                        size={24}
                                    />
                                </div>
                            </Tooltip>
                        )}
                        <MultiLevelSelect
                            data={[
                                {
                                    Brands: {
                                        type: 'multiSelect',
                                        data: brands,
                                        isSelectAll: true,
                                    },
                                },
                            ]}
                            values={filters}
                            onChange={v => {
                                setFilters(v);
                            }}
                            loading={brandLoading}
                            maxWidth={800}
                            selectText="Brands"
                        />
                    </div>
                    <Tooltip label="Coming soon" withArrow>
                        <div>
                            <WeeksPicker
                                handleChange={w => setWeeks(w)}
                                disabled
                                fullLabel="Year to date"
                            />
                        </div>
                    </Tooltip>
                </div>
            </div>
            <ChartContainer
                title=""
                loading={isLoading || brandLoading}
                className="mt-6 !h-[76vh]"
                childrenClassName="!h-[64vh]"
                disableMinHeight
            >
                <div className="mb-4 text-base text-navy-solid-70">
                    Distribution of ABI vs non-ABI recommendations in the top 10 positions
                </div>
                <BarChart
                    data={{
                        datasets: chartData,
                    }}
                    legend={{ display: false }}
                    tooltip={{
                        mode: 'index',
                        callbacks: {
                            label: context =>
                                Number(context['raw']['y']) > 0 &&
                                `${context['dataset']['label']}: ${formatNumber(
                                    Number(context['raw']['y']) / 100,
                                    '0[.]00%',
                                )}`,
                        },
                        itemSort: (a, b) => b['raw']['y'] - a['raw']['y'],
                    }}
                    stackedBars
                    yAxis={{
                        stacked: true,
                        ticks: {
                            callback: val => `${Number(val)}%`,
                        },
                        max: 100,
                    }}
                    xAxis={{
                        stacked: true,
                    }}
                />
                {(!filters?.Brands?.value || filters?.Brands?.value?.length < 1) && (
                    <div className="ml-6 mt-3 flex flex-row items-center justify-start gap-4">
                        <Indicator
                            inline
                            size={8}
                            offset={5}
                            radius="xs"
                            position="middle-start"
                            color="#345DEE"
                            className="pl-3"
                        >
                            ABI brands
                        </Indicator>
                        <Indicator
                            inline
                            size={8}
                            offset={5}
                            radius="xs"
                            position="middle-start"
                            color="#BB54B5"
                            className="pl-4"
                        >
                            Non-ABI brands
                        </Indicator>
                    </div>
                )}
            </ChartContainer>
        </div>
    );
};
