import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { motion } from 'framer-motion';
import { useDebouncedValue } from '@mantine/hooks';
import { SelectItem } from 'andromeda/types/select/common';
import Text from 'andromeda/text';
import classNames from 'classnames';
import useColorScheme from '../../hooks/useColorScheme';
import SearchInput from './searchInput';

function TextAreaDropDown({
    onChange,
    disabled,
    onSubmit,
    loading,
    data = [],
    hideOptionsTitle,
    placeholder,
    revertSearchIcon,
    value,
    className,
    noGradient,
    spinnerColor,
}: {
    onChange?: (s: string) => void;
    onSubmit: ({ query, id }: { query?: string; id?: string }) => void;
    disabled?: boolean;
    loading?: boolean;
    data?: (string | SelectItem)[];
    hideOptionsTitle?: boolean;
    revertSearchIcon?: boolean;
    placeholder?: string;
    value?: string;
    spinnerColor?: string;
    className?: { dropdown?: string; textArea?: string };
    noGradient?: boolean;
}) {
    const { colorScheme } = useColorScheme();
    const [searchText, setSearchText] = useState(value);
    const [searchTextDebounced] = useDebouncedValue(searchText || '', 300);
    const [IsOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if (value === searchText || searchText === '') return;
        setSearchText(value);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    const savedSearches = useMemo(
        () =>
            data.map(item =>
                typeof item === 'string'
                    ? {
                          label: item,
                          group: 'Recent searches',
                          value: item,
                      }
                    : item,
            ),
        [data],
    );

    const filteredData = useMemo<SelectItem[]>(
        () =>
            searchTextDebounced.length > 0
                ? savedSearches.filter(item =>
                      item.label.toLowerCase().includes(searchTextDebounced.toLowerCase()),
                  )
                : savedSearches,
        [savedSearches, searchTextDebounced],
    );

    const handleSubmit = useCallback(
        (v?: string) => {
            const val = (v ?? searchText).trimEnd().trimStart();
            if (val.length === 0) return;
            onSubmit({ id: val });
        },
        [onSubmit, searchText],
    );

    const shouldOpen = filteredData.length > 0 && IsOpen;
    return (
        <div
            className="relative flex w-full flex-col gap-[10px]"
            onFocus={() => setIsOpen(true)}
            onBlur={e => {
                setIsOpen(false);
            }}
        >
            <SearchInput
                disabled={disabled}
                loading={loading}
                searchText={searchText}
                onSearchTextChange={e => {
                    setSearchText(e);
                    onChange?.(e);
                }}
                className={className?.textArea}
                handleSubmit={() => handleSubmit()}
                placeholder={placeholder ?? 'Enter a product ID (example: 2-719)'}
                revertSearchIcon={revertSearchIcon}
                noGradient={noGradient}
                spinnerColor={spinnerColor}
            />
            <motion.div
                style={{
                    border: `0.5px solid ${
                        colorScheme === 'light' ? 'rgba(0, 0, 0, 0.15)' : 'rgba(255, 255,255, 0.15)'
                    }`,
                }}
                animate={{
                    height: shouldOpen ? 156 : 0,
                    padding: (!shouldOpen && '0px') || undefined,
                    border: (!shouldOpen && 'none') || undefined,
                }}
                className={classNames(
                    'mt-[43px] absolute z-40 max-h-[156px] w-full overflow-auto rounded flex flex-col self-end p-1',
                    className?.dropdown ?? '',
                    { 'gradient-bg-hex': !noGradient },
                )}
            >
                {filteredData.length > 0 && (
                    <div key="Recent searches-select-group" className="w-full">
                        {!hideOptionsTitle && (
                            <Text type="10Reg" className="px-1 py-[5px] !text-navy-solid-30">
                                Recent searches
                            </Text>
                        )}
                        {filteredData.map(item => (
                            <button
                                type="button"
                                className="my-0.5 flex w-full items-center rounded p-1 text-start capitalize text-navy-solid-50 hover:!bg-navy-solid-5 hover:text-navy-solid-70"
                                key={`${item?.['group']}-${item.label}`}
                                onClick={() => {
                                    onChange(item.value);
                                    setSearchText(item.value);
                                    setIsOpen(false);
                                }}
                            >
                                <Text type="14Reg" className="!text-inherit">
                                    {item.label}
                                </Text>
                            </button>
                        ))}
                    </div>
                )}
            </motion.div>
        </div>
    );
}

export default TextAreaDropDown;
