import cx from 'classnames';

import type { ReactNode } from 'react';
import type { CommonProps } from '@/responsive/types';

const HeaderActionsWrapper = ({
    children,
    className,
    icon,
}: CommonProps & { icon?: ReactNode }) => (
    <div className="flex items-start gap-4">
        <div
            className={cx(
                'flex flex-col gap-2 max-md:flex-row max-sm:flex-col lg:flex-row',
                className,
            )}
        >
            {children}
        </div>
        {icon && icon}
    </div>
);

export default HeaderActionsWrapper;
