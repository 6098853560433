/* eslint-disable react/no-unstable-nested-components */
import React, { useMemo, useState } from 'react';
import { ChartLine, Check, CheckCircle, Copy, ThumbsDown, ThumbsUp, Wrench } from 'phosphor-react';
import cx from 'classnames';
import { useTimeout } from '@mantine/hooks';
import { useRouter } from 'next/router';
import { useIsMutating } from '@tanstack/react-query';
import { Image, LoadingOverlay, Menu } from '@mantine/core';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { Card } from 'andromeda/Card';
import Text from 'andromeda/text';
import { primaryColors } from '@/constants/colors';
import { DateFormatter } from '@/utils/DateFormatter';
import { ResponsiveEllipsisText } from '../utils/ResponsiveEllipsisText';
import { useRecommendationsData } from './useRecommendationsData';
import { IconWrapperButton } from '../utils/IconWrapperButton';
import { useBillingData } from './BillingSection/useBillingData';
import { useLikeRec } from './useLikeRec';
import { Table } from '../../andromeda/Table';
import { Personalization } from '../../types/Personalization';
import { usePersonalizationsQueryParams } from './usePersonalizationsQueryParams';
import { CONSOLE_MODULE_NAME_MAP } from '../console/ConsoleModule';
import { NotifyButton } from './NotifyButton';

interface IconButtonProps {
    type: 'like' | 'dislike';
    value?: boolean;
    productID: string;
}

const IconButton: React.FC<IconButtonProps> = ({ type, productID, value }) => {
    const { mutate: likeRecMutate, isPending: likeRecIsLoading } = useLikeRec(productID);
    const isMutating = useIsMutating({ mutationKey: ['likeRec', productID] });

    const loading = likeRecIsLoading || !!isMutating;

    const Icon = type === 'like' ? ThumbsUp : ThumbsDown;

    return (
        <button
            type="button"
            className="relative p-1 text-gray-400 disabled:cursor-default"
            onClick={() => likeRecMutate({ productID, type })}
            disabled={loading}
            aria-label={type === 'like' ? 'Thumbs Up' : 'Thumbs Down'}
        >
            <Icon
                size={18}
                className={cx({
                    'text-green-500': value === true && type === 'like',
                    'text-red-500': value === true && type === 'dislike',
                    'opacity-50': loading,
                })}
                weight={value ? 'fill' : 'regular'}
            />
            <LoadingOverlay
                visible={loading}
                loaderProps={{
                    variant: 'dots',
                    size: 'sm',
                    color: '#9ca3af',
                }}
                classNames={{ overlay: '!opacity-0' }}
            />
        </button>
    );
};

interface ActionsElementProps {
    onCopy: () => void;
    isUS: boolean;
    copyDisabled: boolean;
    selectedNamespace: string;
    selectedUseCase: string;
}

const ActionsElement = ({
    onCopy,
    isUS,
    copyDisabled,
    selectedNamespace,
    selectedUseCase,
}: ActionsElementProps) => {
    const router = useRouter();

    const [isCopied, setIsCopied] = useState(false);

    const { start } = useTimeout(() => setIsCopied(false), 2000);

    const onClickCopy = () => {
        setIsCopied(true);
        start();
        onCopy();
    };

    return (
        <div className="flex items-center gap-3">
            {!copyDisabled && (
                <IconWrapperButton
                    description="Paste these SKUs into the billing data search bar"
                    onClick={onClickCopy}
                >
                    {isCopied && <Check size={22} className="text-primary-60" />}
                    {!isCopied && <Copy size={22} />}
                </IconWrapperButton>
            )}
            {!isUS && (
                <>
                    <IconWrapperButton
                        description="Results"
                        onClick={() =>
                            router.push(
                                `/experiments?namespace=${selectedNamespace}&usecase=${selectedUseCase}&currentView=results`,
                            )
                        }
                    >
                        <ChartLine size={22} />
                    </IconWrapperButton>
                    <IconWrapperButton
                        description="configurations"
                        onClick={() =>
                            router.push(
                                `/configurations?namespace=${selectedNamespace}&usecase=${selectedUseCase}`,
                            )
                        }
                    >
                        <Wrench size={22} />
                    </IconWrapperButton>
                </>
            )}
        </div>
    );
};

const columnHelper = createColumnHelper<Personalization>();

interface RecommendationsSectionProps {
    className?: string;
    updateBillingSkuSearch: (skus: string[]) => void;
    showDebug: boolean;
    loading: boolean;
    accountID: string;
}

export const RecommendationsSection: React.FC<RecommendationsSectionProps> = ({
    className,
    updateBillingSkuSearch,
    showDebug,
    accountID,
    loading,
}) => {
    const { selectedNamespace, selectedUseCase } = usePersonalizationsQueryParams();
    const isUS = selectedNamespace.includes('US-');
    const { data, isLoading, error } = useRecommendationsData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const personalization = data?.personalization ?? [];
    const { data: billingData, error: billingError } = useBillingData();

    const matchedSkusIds = useMemo(() => {
        if (!billingData) return [];
        const skus = personalization.map(rec => rec.product_name).filter(Boolean);
        const matchedSkus = skus.filter(sku => billingData.some(item => item.product_name === sku));

        return matchedSkus.map(
            sku => billingData.find(item => item.product_name === sku)?.product_id,
        );
    }, [billingData, personalization]);

    const onCopy = () => {
        updateBillingSkuSearch(matchedSkusIds);
    };

    const columns = useMemo<ColumnDef<Personalization>[]>(() => {
        const cols = [
            {
                header: ' ',
                id: 'index',
                cell: ({ row }) => <div>{row.index + 1}</div>,
            },
            columnHelper.accessor('product_id', {
                header: 'Product',
                cell: info => info.getValue(),
            }),
            columnHelper.accessor('product_name', {
                header: 'Name',
                cell: info => <ResponsiveEllipsisText>{info.getValue()}</ResponsiveEllipsisText>,
                enableHiding: true,
            }),
            columnHelper.accessor('quantity', {
                header: 'Quantity',
                cell: info => info.getValue(),
            }),
            columnHelper.accessor('image_url', {
                header: 'Image',
                cell: info => (
                    <Menu trigger="click" disabled={!info.getValue()}>
                        <Menu.Target>
                            {(info.getValue() && (
                                <Image
                                    src={info.getValue()}
                                    alt=" "
                                    styles={{
                                        root: {
                                            width: 40,
                                            height: 40,
                                        },
                                    }}
                                    className="cursor-pointer"
                                />
                            )) || <div />}
                        </Menu.Target>
                        <Menu.Dropdown>
                            <Image
                                src={info.getValue()}
                                alt=" "
                                styles={{
                                    root: {
                                        maxWidth: '40vw',
                                        maxHeight: '40vh',
                                    },
                                }}
                            />
                        </Menu.Dropdown>
                    </Menu>
                ),
            }),
        ];
        if (!showDebug) {
            cols.push(
                columnHelper.accessor('user_rating', {
                    header: ' ',
                    cell: info => {
                        const userRating = info.getValue();
                        const productID = info.row.original.product_id;

                        return (
                            <div className="relative flex items-center justify-end gap-1">
                                <IconButton
                                    type="like"
                                    value={userRating === 1}
                                    productID={productID}
                                />
                                <IconButton
                                    type="dislike"
                                    value={userRating === -1}
                                    productID={productID}
                                />
                            </div>
                        );
                    },
                }),
            );
        }
        return cols;
    }, [showDebug]);

    return (
        <div className={cx(className, 'relative')}>
            <Card
                title={
                    <div className="flex space-x-2">
                        <div>
                            {(showDebug && (
                                <div className="flex flex-col gap-[10px] pt-8">
                                    <div className="absolute top-[17.5px] flex items-center justify-start space-x-2">
                                        <Text
                                            type="12Mid"
                                            className="w-fit rounded-full bg-primaryBlue px-2 py-1 !text-gray-300"
                                        >
                                            Step 1: Exported by Arena
                                        </Text>
                                        {(data?.is_default_rec || personalization.length === 0) && (
                                            <div>
                                                <NotifyButton
                                                    accountID={accountID}
                                                    section="Arena"
                                                    className="text-xs"
                                                />
                                            </div>
                                        )}
                                    </div>
                                    <Text type="12Reg" className="!text-[11px] text-navy-solid-50">
                                        These are the recommendations Arena exports to BEES.
                                    </Text>
                                </div>
                            )) ||
                                (isUS
                                    ? CONSOLE_MODULE_NAME_MAP[selectedUseCase]
                                    : data?.is_default_rec || personalization.length === 0
                                      ? 'Default recommendation'
                                      : 'Today’s recommendations')}
                        </div>
                        {!showDebug && (data?.is_default_rec || personalization.length === 0) && (
                            <div>
                                <NotifyButton accountID={accountID} section="Arena" />
                            </div>
                        )}
                    </div>
                }
                actionsElement={
                    <ActionsElement
                        onCopy={onCopy}
                        copyDisabled={matchedSkusIds?.length === 0}
                        isUS={isUS}
                        selectedNamespace={selectedNamespace}
                        selectedUseCase={selectedUseCase}
                    />
                }
                loading={isLoading || loading}
                tooltipElement={
                    <>
                        <div className="flex flex-row items-center gap-3 text-xs text-navy-solid-30">
                            Recommendations {data?.last_updated_ts && 'were last updated at '}
                            {data?.last_updated_ts &&
                                DateFormatter.formatDate(data?.last_updated_ts)}{' '}
                            <CheckCircle size={16} color="green" />
                        </div>
                        {data?.model_id && (
                            <Text type="10Reg" className="!text-navy-solid-30">
                                Model: {data?.model_id}
                            </Text>
                        )}
                    </>
                }
                innerClassName="h-3/4"
                error={
                    ((!data?.last_updated_ts && !data?.is_default_rec) || error) &&
                    `No personalizations found for this account ${(data?.last_updated_ts && `for date ${DateFormatter.formatDate(data?.last_updated_ts)}`) || ''}`
                }
                titleClassName="flex flex-col"
                className="h-full"
            >
                <div className="max-h-[310px] min-h-[345px] overflow-y-auto lg:!max-h-[338px]">
                    <Table
                        data={personalization}
                        columns={columns}
                        noDataMessage="There are no personalized recommendations for this store at this time. Please email abi-ghq-support@arena-ai.com if you have any questions."
                        stickyHeader
                        disableHeaderMargin
                    />
                </div>
            </Card>
        </div>
    );
};
