import React, { useEffect } from 'react';
import { Dropdown, DropdownOption } from '../../andromeda/Dropdown';
import { parseLanguage } from '../../utils/parseLanguage';

interface GeoLevel {
    id: string;
    column_name: string;
    level: number;
}

interface ProductLevel {
    id: string;
    column_name: string;
    level: number;
}

interface PersonalizationContext {
    id: string;
    personalization_type: string;
    context: string;
    geo_level: GeoLevel;
    geo_level_value: string;
    product_level: ProductLevel;
    product_level_value: string;
}

interface LanguageDropdownProps {
    contexts?: PersonalizationContext[];
    language?: string;
    onChange: (language: string) => void;
}

export const LanguageDropdown: React.FC<LanguageDropdownProps> = ({
    language,
    onChange,
    contexts,
}) => {
    const parsedJsonContexts = contexts
        ?.filter(item => item.personalization_type === 'json')
        ?.map(item => JSON.parse(item.context ?? '{}'));

    const languageContexts = parsedJsonContexts?.filter(item => item.language);

    const languageOptions: DropdownOption[] = languageContexts?.map(item => ({
        value: item.language,
        label: parseLanguage(item?.language),
    }));

    useEffect(() => {
        if (
            languageOptions?.length &&
            (!language || !languageOptions.find(item => item.value === language))
        ) {
            const defaultLanguageContext = languageContexts?.find(item => item.default);
            onChange(defaultLanguageContext?.language);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contexts]);

    return <Dropdown options={languageOptions} value={language} onChange={onChange} />;
};
