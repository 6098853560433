import { useCallback, useEffect, useMemo, useState } from 'react';
import { capitalize, keyBy } from 'lodash';
import { Modal, Switch } from '@mantine/core';
import dynamic from 'next/dynamic';
import { Button } from 'andromeda';
import { CodeHighlight } from '@mantine/code-highlight';
import { TreeStructure } from 'phosphor-react';
import { GradientBadge } from '../utils/GradientBadge';
import { primaryColors } from '../../constants/colors';
import { useAccountList } from './useAccountList';
import { AccountItem } from './types';

import { usePersonalizationsQueryParams } from './usePersonalizationsQueryParams';
import TextAreaDropDown from '../tbd/textAreaDropdown';
import { useAccount } from './useAccount';
import StyledTooltip from '../utils/styledTooltip';
import { useModelOutputSchema } from './useModelOutputSchema';
import '@mantine/code-highlight/styles.css';

const StoreSelectionMap = dynamic(() => import('./storeSelectionMap'));

const formatSegment = (segment: string) => {
    const innerSegment = segment.trim().toLowerCase();

    if (innerSegment.includes('pharmacy')) {
        return 'Pharmacy';
    }
    if (innerSegment.includes('sari sari')) {
        return 'Sari sari';
    }

    if (innerSegment.includes('stall')) {
        return 'Market stall';
    }

    if (innerSegment.includes(' small')) {
        return capitalize(innerSegment.replace(' small', ''));
    }

    return capitalize(innerSegment);
};

interface StoreSelectionProps {
    showDebug: boolean;
    setShowDebug: (bool: boolean) => void;
}

export const StoreSelection = ({ showDebug, setShowDebug }: StoreSelectionProps) => {
    const { selectedNamespace, accountID, updateSelectedStore, selectedUseCase } =
        usePersonalizationsQueryParams();
    const isUS = selectedNamespace.includes('US-');
    const [filteredStores, setFilteredStores] = useState<AccountItem[]>([]);
    const [modelSchema, setModelSchema] = useState(false);

    const { data: stores, isLoading: storesLoading } = useAccountList(selectedNamespace);
    const { data: schemaData, isLoading: schemaLoading } = useModelOutputSchema(
        selectedUseCase,
        selectedNamespace,
    );

    const codeSchema = useMemo(
        () =>
            schemaData?.reduce(
                (acc, item, i) =>
                    `${acc}\n\t${item.column_name}: ${item.type};${i === schemaData.length - 1 ? '\n}' : ''}`,
                `{`,
            ),
        [schemaData],
    );
    useEffect(() => {
        if (storesLoading && !stores) {
            return;
        }
        setFilteredStores(stores);
    }, [stores, storesLoading]);

    const storesByID = useMemo(() => keyBy(stores, 'customer_account_id'), [stores]);
    const selectedStore = useMemo(() => storesByID[accountID], [storesByID, accountID]);

    const { data: accountData, isLoading: accountDataLoading } = useAccount({
        namespace: selectedNamespace,
        accountID,
        disabled: isNaN(selectedStore?.longitude) || isNaN(selectedStore?.latitude),
    });

    const storeOptions = useMemo(
        () => [
            ...Object.values(
                filteredStores?.reduce(
                    (acc: Record<string, { value: string; label: string }>, store) => ({
                        ...acc,
                        [store.customer_account_id]: {
                            value: store.customer_account_id,
                            label: `${store.account_name} (${store.customer_account_id})`,
                        },
                    }),
                    {},
                ) ?? [],
            ),
        ],
        [filteredStores],
    );

    const addressLine = [selectedStore?.address, selectedStore?.city, selectedStore?.province]
        .filter(Boolean)
        .join(', ');

    const handleStoreChange = useCallback(
        (id: string) => {
            updateSelectedStore({
                accountId: id,
            });
        },
        [updateSelectedStore],
    );

    return (
        <div className="store-selection">
            <div className="relative mb-2 flex w-full items-center justify-between gap-2">
                <div className="flex items-center justify-between">
                    <div className="w-[320px]">
                        <TextAreaDropDown
                            data={storeOptions}
                            onSubmit={({ id, query }) => handleStoreChange(id ?? query)}
                            onChange={handleStoreChange}
                            hideOptionsTitle
                            placeholder="Enter an account id (S100901_22)"
                            revertSearchIcon
                            value={accountID}
                            loading={storesLoading}
                        />
                    </div>
                </div>

                <div className="flex flex-row items-center gap-4">
                    {!isUS && (
                        <div className="flex items-center gap-2">
                            {selectedStore?.segment && (
                                <GradientBadge colors={[primaryColors.blue, primaryColors.blue]}>
                                    {formatSegment(selectedStore.segment)}
                                </GradientBadge>
                            )}
                        </div>
                    )}
                    <StyledTooltip
                        label="View the recommendations at each step of the pipeline journey."
                        withArrow
                    >
                        <div className="cursor-pointer">
                            <Switch
                                label="Debug"
                                checked={showDebug}
                                classNames={{
                                    track: showDebug && '!bg-primaryBlue',
                                }}
                                onChange={event => setShowDebug(event.currentTarget.checked)}
                            />
                        </div>
                    </StyledTooltip>
                    <Button
                        disabled={!schemaData || schemaData.length === 0 || modelSchema}
                        onClick={() => setModelSchema(true)}
                        loading={schemaLoading}
                        variant="secondary"
                        className="group"
                        leftSection={
                            <TreeStructure
                                size={20}
                                className="text-primaryBlue group-hover:text-navy-solid-50 group-disabled:text-navy-solid-30"
                            />
                        }
                    >
                        Model Schema
                    </Button>
                </div>
            </div>
            <div className="mb-2 mt-4 flex w-fit pl-2">
                {!isUS ? (
                    <div className="text-xs font-extralight text-navy-solid-50">
                        <p className="text-base font-medium">{selectedStore?.account_name}</p>
                        <p className="text-gray-400">{addressLine}</p>
                    </div>
                ) : (
                    <p className="text-xs text-gray-400">ADDRESS: {addressLine}</p>
                )}
            </div>
            {accountID && (
                <StoreSelectionMap
                    selectedStore={accountData?.[0] ?? selectedStore}
                    loading={accountDataLoading}
                />
            )}
            <Modal
                opened={modelSchema}
                onClose={() => setModelSchema(false)}
                title="Model Recommendations Schema required by Arena"
            >
                <CodeHighlight
                    className="my-4 !bg-secondary-2"
                    language="python"
                    title="CODE SNIPPET"
                    code={codeSchema}
                />
            </Modal>
        </div>
    );
};
