import { useMutation } from '@tanstack/react-query';
import { notification } from '@/utils/notification';
import { Check, X } from 'phosphor-react';
import colors from '@/constants/colors';
import { useAppContext } from '@/contexts/appContext';
import { formatUseCaseName } from '@/components/utils/UseCaseDropdown';
import { SPACEPORT_API_URL } from '../buildConfig/processEnv';
import useApiQuery from './useApiQuery';

export const getUseUploadDataUrl = (
    useCase: string,
    namespace: string,
    recsTier?: string,
    vendor_account_id?: string,
) =>
    `${SPACEPORT_API_URL}/spaceport/api/exports/export/use_case/${useCase}/namespace/${namespace}?use_case=${useCase}&namespace=${namespace}&${recsTier ? `&recommendation_tier=${recsTier}` : ''}${vendor_account_id ? `&vendor_account_id=${vendor_account_id}` : ''}`;

export type UploadProductDataType = {
    product_name: string | null;
    product_id: string;
    quantity: string;
    vendor_account_id?: string;
    score?: number;
    // FE
    isRemoved?: boolean;
    isNew?: boolean;
};
export type UploadDataType = {
    use_case: string;
    account_id: string;
    recommendation_id: string;
    products: UploadProductDataType[];
    url: string;
    date: string;
};

export const useUploadData = ({
    namespace,
    usecase,
    recsTier,
    vendorAccountID,
    disabled,
}: {
    namespace: string;
    usecase: string;
    recsTier?: 'data' | 'business';
    vendorAccountID?: string;
    disabled?: boolean;
}) =>
    useApiQuery<UploadDataType>({
        url: getUseUploadDataUrl(usecase, namespace, recsTier, vendorAccountID),
        method: 'GET',
        disabled: !namespace || disabled,
    });

const request = async ({
    url,
    body,
    token,
}: {
    url: string;
    body: { path_to_artifact: string };
    token: string;
}) => {
    const response = await fetch(url.toString(), {
        headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${token}`,
        },
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify(body),
    });

    if (response.status >= 400) {
        return Promise.reject(response);
    }

    const results = await response.json();
    return results;
};

export const generateExportID = ({
    namespace,
    path_to_artifact,
    use_case,
}: {
    use_case: string;
    namespace: string;
    path_to_artifact: string;
}) =>
    JSON.stringify({
        namespace,
        use_case,
        path_to_artifact,
    });

export const useExportData = ({
    setExportArtifactsStates,
    setExportArtifacts,
}: {
    setExportArtifactsStates: React.Dispatch<
        React.SetStateAction<{ total: number; failed: number; success: number }>
    >;
    setExportArtifacts: React.Dispatch<
        React.SetStateAction<
            Record<
                string,
                {
                    use_case: string;
                    namespace: string;
                    path_to_artifact: string;
                }
            >
        >
    >;
}) => {
    const { user } = useAppContext();

    const removeSentValueToBEFromStack = (
        val: {
            use_case: string;
            namespace: string;
            path_to_artifact: string;
        },
        stat: { failed?: number; success?: number },
    ) => {
        const id = generateExportID(val);
        setExportArtifacts(prev =>
            Object.entries(prev).reduce((acc, [k, v]) => (k === id ? acc : { ...acc, [k]: v }), {}),
        );
        setExportArtifactsStates(prev => ({
            ...prev,
            failed: prev.failed + (stat.failed ?? 0),
            success: prev.success + (stat.success ?? 0),
        }));
    };

    return useMutation({
        mutationKey: [`exporting artifacts`],
        mutationFn: ({
            body,
            namespace,
            useCase,
        }: {
            useCase: string;
            namespace: string;
            body: { path_to_artifact: string };
        }) =>
            request({
                url: getUseUploadDataUrl(useCase, namespace),
                token: user?.siberiaToken,
                body,
            }),
        onError(e, a) {
            removeSentValueToBEFromStack(
                {
                    namespace: a.namespace,
                    path_to_artifact: a.body.path_to_artifact,
                    use_case: a.useCase,
                },
                { failed: 1 },
            );
            notification({
                message: `Failed to export ${e?.['namespace']}/${e?.['use_case']}`,
                icon: <X color={colors.red} size={14} />,
            });
        },
        onSuccess(e, a) {
            removeSentValueToBEFromStack(
                {
                    namespace: a.namespace,
                    path_to_artifact: a.body.path_to_artifact,
                    use_case: a.useCase,
                },
                { success: 1 },
            );

            notification({
                message: `Successfully exported ${e?.['namespace']}/${formatUseCaseName(e?.['use_case'])}`,
                icon: <Check color={colors.green} size={14} />,
            });
        },
    });
};
