import { BOREALIES_API_URL } from '../buildConfig/processEnv';
import useApiQuery from './useApiQuery';

export interface NamespaceItem {
    id: string;
    name: string;
    organization: string;
}

const url = `${BOREALIES_API_URL}/spaceport/api/common/namespace`;

export const useNamespaceList = (disabled?: boolean) =>
    useApiQuery<NamespaceItem[]>({ url, disabled });
