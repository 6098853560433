import ResponsiveLayout from '@/components/responsive/ResponsiveLayout';
import { PERSONALIZATIONS_USER_GROUP_WHITELIST } from '@/components/navBar/constants';
import { UserGuard } from '@/components/utils/UserGuard';
import { PersonalizationsPage as Personalizations } from '../components/personalizations/PersonalizationsPage';

export default function PersonalizationsPage() {
    return (
        <ResponsiveLayout title="Personalizations">
            <UserGuard userGroupWhitelist={PERSONALIZATIONS_USER_GROUP_WHITELIST}>
                <Personalizations />
            </UserGuard>
        </ResponsiveLayout>
    );
}
