import React from 'react';
import { MagnifyingGlass } from 'phosphor-react';
import classNames from 'classnames';
import { Spinner } from 'andromeda';
import useColorScheme from '../../hooks/useColorScheme';

function SearchInput({
    handleSubmit,
    onSearchTextChange,
    searchText,
    loading,
    disabled,
    placeholder,
    revertSearchIcon,
    className,
    noGradient,
    spinnerColor,
}: {
    handleSubmit?: () => void;
    onSearchTextChange?: (str: string) => void;
    searchText: string;
    placeholder?: string;
    className?: string;
    spinnerColor?: string;
    loading?: boolean;
    disabled?: boolean;
    revertSearchIcon?: boolean;
    noGradient?: boolean;
}) {
    const { colorScheme } = useColorScheme();

    return (
        <form
            className={classNames(
                'relative flex rounded w-full !text-navy-solid-70 focus:!shadow-none focus-within:!shadow-none focus-within:border-b-0.5 focus:border-b-0.5 focus:border-r-0.5 focus-within:border-r-0.5 text-sm border-t-0.5 border-l-0.5',
                {
                    'flex-row-reverse': revertSearchIcon,
                    'gradient-bg-hex': !noGradient,
                    'border-0.5 border-border-color': noGradient,
                },
                className,
            )}
            style={
                (!noGradient && {
                    boxShadow: `4px 4px 8px 0px  ${
                        colorScheme === 'light'
                            ? 'rgba(10, 12, 15, 0.05)'
                            : 'rgba(245, 248,240, 0.15)'
                    } `,
                    backdropFilter: 'blur(8px)',
                    borderColor:
                        colorScheme === 'light'
                            ? 'rgba(0, 0, 0, 0.15)'
                            : 'rgba(255, 255,255, 0.15)',
                }) ||
                undefined
            }
            onSubmit={e => {
                e.preventDefault();
                handleSubmit?.();
            }}
        >
            <input
                className="w-full overflow-hidden bg-transparent py-2 pl-2 pr-1.5 !text-[15px] !text-navy-solid-70 outline-none placeholder:!text-navy-solid-15"
                placeholder={placeholder}
                onChange={e => {
                    onSearchTextChange(e.currentTarget.value);
                }}
                value={searchText}
            />
            <div className="flex items-center justify-center">
                {loading ? (
                    <Spinner size={16} className="!w-9" color={spinnerColor} />
                ) : (
                    <button
                        type="button"
                        className="flex size-9 items-center justify-center text-navy-solid-50 outline-none disabled:text-navy-solid-15"
                        onClick={handleSubmit}
                        disabled={disabled}
                        aria-label="submit entered value"
                    >
                        <MagnifyingGlass height="16px" width="16px" className="text-inherit" />
                    </button>
                )}
            </div>
        </form>
    );
}

export default SearchInput;
