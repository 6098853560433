import { SPACEPORT_API_URL } from '../../buildConfig/processEnv';
import useApiQuery from '../../hooks/useApiQuery';
import { AccountItem } from './types';

export const getAccountListUrl = (namespace: string, limit?: number) =>
    `${SPACEPORT_API_URL}/spaceport/api/personalizer/personalize/namespace/${namespace}/accounts_list`;

export const useAccountList = (namespace: string, limit?: number) =>
    useApiQuery<AccountItem[]>({
        url: getAccountListUrl(namespace, limit),
        disabled: !namespace,
    });
