/* eslint-disable @next/next/no-img-element */
import React from 'react';
import { Card } from 'andromeda/Card';
import { primaryColors } from '@/constants/colors';
import { useRecommendationsData } from './useRecommendationsData';
import { UseCaseConfig } from '../../types/Personalization';

interface ContextualImageProps {
    platform: 'WEB' | 'APP';
    useCaseConfig?: UseCaseConfig;
    language: string;
}

export const ContextualImage: React.FC<ContextualImageProps> = ({
    platform,
    useCaseConfig,
    language,
}) => {
    const { isLoading, error } = useRecommendationsData();

    const contexts = useCaseConfig?.personalization_contexts;

    const parsedJsonContexts = contexts
        ?.filter(item => item.personalization_type === 'json')
        ?.map(item => JSON.parse(item.context ?? '{}'));

    const selectedContext = parsedJsonContexts?.find(item => item.language?.includes(language));

    const images = selectedContext?.images;
    const platformImage = images?.find(item => item.key?.includes(platform));
    const image = platformImage?.value;

    return (
        <Card
            loading={isLoading}
            error={error?.message}
            title={`Personalized contextual image - ${platform}`}
            className="col-span-1"
        >
            <img src={image} alt={language} className="h-80 object-contain" />
        </Card>
    );
};
